// Generated by Framer (26d4882)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {YY3A0hfuI: {hover: true}};

const cycleOrder = ["YY3A0hfuI"];

const serializationHash = "framer-lPqmf"

const variantClassNames = {YY3A0hfuI: "framer-v-10qaqa1"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({email, height, id, width, ...props}) => { return {...props, oXAX1NbX_: email ?? props.oXAX1NbX_ ?? "tanjimislam27@gmail.com "} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;email?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, oXAX1NbX_, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "YY3A0hfuI", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-lPqmf", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-10qaqa1", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"YY3A0hfuI"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"YY3A0hfuI-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--framer-font-size": "26px", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-d7fd7e6b-f15e-4d47-862f-8dcb06e3cf61, rgba(255, 255, 255, 0.37)))"}}>tanjimislam27@gmail.com </motion.p></React.Fragment>} className={"framer-1pq2lay"} layoutDependency={layoutDependency} layoutId={"M7j2idYkf"} style={{"--extracted-r6o4lv": "var(--token-d7fd7e6b-f15e-4d47-862f-8dcb06e3cf61, rgba(255, 255, 255, 0.37))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} text={oXAX1NbX_} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-lPqmf [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-lPqmf .framer-yn8mwv { display: block; }", ".framer-lPqmf .framer-10qaqa1 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 400px; }", ".framer-lPqmf .framer-1pq2lay { flex: 1 0 0px; height: auto; position: relative; white-space: pre-wrap; width: 1px; word-break: break-word; word-wrap: break-word; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-lPqmf .framer-10qaqa1 { gap: 0px; } .framer-lPqmf .framer-10qaqa1 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-lPqmf .framer-10qaqa1 > :first-child { margin-left: 0px; } .framer-lPqmf .framer-10qaqa1 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 38
 * @framerIntrinsicWidth 400
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"KO8qJ1auB":{"layout":["fixed","auto"]}}}
 * @framerVariables {"oXAX1NbX_":"email"}
 * @framerImmutableVariables true
 */
const FramerzQJ_XOQBg: React.ComponentType<Props> = withCSS(Component, css, "framer-lPqmf") as typeof Component;
export default FramerzQJ_XOQBg;

FramerzQJ_XOQBg.displayName = "Mail copy";

FramerzQJ_XOQBg.defaultProps = {height: 38, width: 400};

addPropertyControls(FramerzQJ_XOQBg, {oXAX1NbX_: {defaultValue: "tanjimislam27@gmail.com ", displayTextArea: false, title: "Email", type: ControlType.String}} as any)

addFonts(FramerzQJ_XOQBg, [])